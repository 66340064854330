import { t } from "i18next";
import styled from "styled-components";
import { LicenseType } from "../../../type/LicenseType";
import Price from "../pricing/Price";
import { Trans } from "react-i18next";
import { BonusCode } from "../../../type/BonusCode";
import React from "react";
import { GetPriceResponse } from "../../../type/responses/GetPriceResponse";
import { DiscountSource } from "../../../type/DiscountSource";
import AseclaButton from "../../utils/AseclaButton";

type AseclaProductsColumnParams = {
    columnName: string,
    stepNo: number,
    licType: LicenseType,
    quantity?: number,
    length?: number,
    bonusCode?: BonusCode,
    bonusCodeValid?: boolean,
    goStepBack?: () => void,
}
function AseclaProductsColumn({columnName, stepNo, licType, quantity, length, bonusCode, bonusCodeValid, goStepBack}: AseclaProductsColumnParams) {
    const [ priceResponse, setPriceResponse] = React.useState<GetPriceResponse | null>(null);

    let costRow = <></>;
    let discountRow = <></>;
    if (stepNo === 1 || stepNo === 2) {
        costRow = <div className="wordField lightWordField bitCrowdedField">
                        {<Trans i18nKey="PriceColumnformatting" 
                            components={{
                                BigSpan: <BigSpan></BigSpan>,
                                price: <Price length={1} licenseType={licType!} quantity={1}
                                    bonusCode={bonusCode} bonusCodeValid={bonusCodeValid} showDiscount={true}
                                    showHint={false} setPrice={setPriceResponse}></Price>
                                // price: <PriceCaption price={licType.price} currency="zł" priceToCurrencySpace={false}></PriceCaption>
                            }}
                            values={{
                                priceDescription: t("Price per license per year description")
                            }}
                        ></Trans>}
                    </div>;
    }

    if ( priceResponse !== null) {
        if (priceResponse.discountSource === DiscountSource.Organization) {
            discountRow = <div className="wordField lightWordField crowdedWordField">{t("The discount is based on your Organization Discount") as string}</div>
        }
        if (priceResponse.discountSource === DiscountSource.BonusCode) {
            discountRow = <div  className="wordField lightWordField crowdedWordField">{t("The discount comes from your bonuscode") as string}</div>
        }
    }

    return <>
        {stepNo === 2 && goStepBack !== undefined && <><AseclaButton className="small" action={e => {goStepBack!()}}>{t("<< Zmień zamówienie") as string}</AseclaButton></>}
        <PaddingDiv className="product_pricing_table">
            {columnName === "Hello" &&
                <div className="column column1">
                    <div className="wordField lightWordField">{t("Ilość Użytkowników: do", {users: 3}) as string}</div>
                    <div className="wordField lightWordField">{t("File up to 100 MB") as string}</div>
                    <div className="wordField lightWordField">{t("Mięsięcznie do ", {limit: "1 GB"}) as string}</div>
                    <div className="wordField lightWordField bitCrowdedField">{t("Full E2E encryption (AES-256)") as string}</div>
                    <div className="wordField lightWordField bitCrowdedField">{t("Downloading without creating an account") as string}</div>
                    <div className="wordField lightWordField bitCrowdedField">{t("Możliwość dodania hasła: Tak") as string}</div>
                    <div className="wordField lightWordField bitCrowdedField">{t("Dezaktywacja linku: Tak") as string}</div>
                    <div className="wordField lightWordField bitCrowdedField">{t("Wsparcie telefoniczne: Nie") as string}</div>
                    <div className="wordField lightWordField bitCrowdedField">{t("Priorytetowe wsparcie email: Tak") as string}</div>

                    {costRow}
                    {discountRow}
                </div>
            }
            {columnName === "Silver" &&
                <div className="column column2">
                    <div className="wordField lightWordField">{t("Ilość Użytkowników: do", {users: 5}) as string}</div>
                    <div className="wordField lightWordField">{t("File up to 50 GB") as string}</div>
                    <div className="wordField lightWordField">{t("Mięsięcznie do ", {limit: "100 GB"}) as string}</div>
                    <div className="wordField lightWordField bitCrowdedField">{t("Full E2E encryption (AES-256)") as string}</div>
                    <div className="wordField lightWordField bitCrowdedField">{t("Downloading without creating an account") as string}</div>
                    <div className="wordField lightWordField bitCrowdedField">{t("Możliwość dodania hasła: Tak") as string}</div>
                    <div className="wordField lightWordField bitCrowdedField">{t("Dezaktywacja linku: Tak") as string}</div>
                    <div className="wordField lightWordField bitCrowdedField">{t("Wsparcie telefoniczne: Nie") as string}</div>
                    <div className="wordField lightWordField bitCrowdedField">{t("Priorytetowe wsparcie email: Tak") as string}</div>

                    {costRow}
                    {discountRow}
                </div>
            }
            {columnName === "Gold" &&
                <div className="column column3">
                    <div className="wordField lightWordField">{t("Ilość Użytkowników: do", {users: 5}) as string}</div>
                    <div className="wordField lightWordField">{t("File up to 200 GB") as string}</div>
                    <div className="wordField lightWordField">{t("Mięsięcznie do ", {limit: "500 GB"}) as string}</div>
                    <div className="wordField lightWordField bitCrowdedField">{t("Full E2E encryption (AES-256)") as string}</div>
                    <div className="wordField lightWordField bitCrowdedField">{t("Downloading without creating an account") as string}</div>
                    <div className="wordField lightWordField bitCrowdedField">{t("Możliwość dodania hasła: Tak") as string}</div>
                    <div className="wordField lightWordField bitCrowdedField">{t("Dezaktywacja linku: Tak") as string}</div>
                    <div className="wordField lightWordField bitCrowdedField">{t("Wsparcie telefoniczne: Nie") as string}</div>
                    <div className="wordField lightWordField bitCrowdedField">{t("Priorytetowe wsparcie email: Tak") as string}</div>
                    {costRow}
                    {discountRow}
                </div>
            }
            {columnName === "Platinum" &&
                <div className="column column4">
                    <div className="wordField lightWordField">{t("Ilość Użytkowników: do", {users: 5}) as string}</div>
                    <div className="wordField lightWordField">{t("File up to 500 GB") as string}</div>
                    <div className="wordField lightWordField">{t("Mięsięcznie do ", {limit: "2 TB"}) as string}</div>
                    <div className="wordField lightWordField bitCrowdedField">{t("Full E2E encryption (AES-256)") as string}</div>
                    <div className="wordField lightWordField bitCrowdedField">{t("Downloading without creating an account") as string}</div>
                    <div className="wordField lightWordField bitCrowdedField">{t("Możliwość dodania hasła: Tak") as string}</div>
                    <div className="wordField lightWordField bitCrowdedField">{t("Dezaktywacja linku: Tak") as string}</div>
                    <div className="wordField lightWordField bitCrowdedField">{t("Wsparcie telefoniczne: Nie") as string}</div>
                    <div className="wordField lightWordField bitCrowdedField">{t("Priorytetowe wsparcie email: Tak") as string}</div>
                    {costRow}
                    {discountRow}
                </div>
            }
        </PaddingDiv>
    </>
}
export default AseclaProductsColumn;

const BigSpan = styled.div`
    font-weight: 700;
    font-size: 1.2em;
    vertical-align: baseline;
    position: relative;
    top: -0.1em;
    white-space: nowrap;

    span.finalValue {
        font-size: 1.2em;
    }
`;

const PaddingDiv = styled.div`
    div.column.column2,
    div.column.column3,
    div.column.column4 {
        margin-left: 0px;
        margin-right: 0px;
    }

    div.column div.wordField {
        font-weight: 200;
        height: 50px;
    }

    div.column div.wordField.crowdedWordField {
        height: 70px;
    }
        
    div.column div.bitCrowdedField {
        line-height: 20px;
    }

    div.column div.bitCrowdedField {
        @media (max-width: 768px) {
            font-size: clamp(16px, 3vw, 20px);
        }
    }

`;
